<!--
 * @Description: 用户点赞组件
 * @Date: 2022-08-12 11:46:51
 * @LastEditTime: 2023-09-28 13:58:03
-->
<template>
  <div class="heart-component">
    <a
      id="praise-btn"
      href="javascript:void(0);"
      class="praise-btn"
    />
    <div class="praise-bubble" />
  </div>
</template>

<script>

export default {
  name: 'HeartComponent',
  data() {
    return {
      // clickTimeInterval: 0, // 点击时间间隔
      timer: null, // 点赞计时timer
    };
  },
  mounted() {
    this.createEl(4)
  },
  beforeDestroy() {
    console.log('destory');
    clearInterval(this.time);
  },
  methods: {
    /**
     * 点赞按钮点击事件
     *
     * @param from
     */
    createEl(length, delay = 1000) {
      const praiseBubbleDom = document.querySelector('.praise-bubble');
      let time = 0;
      if (praiseBubbleDom) {
          setInterval(() => {
            if (time >= length) return;
            const leave2 = Math.floor(Math.random() * 9) + 1;
            const newDom = document.createElement('div');
            newDom.className = `bubble b1 bl${leave2}`;
            newDom.dataset.t = String(Date.now());
            praiseBubbleDom.appendChild(newDom);
            time++;
          }, delay)
      }
    },
  },
};
</script>

<style>
/* $bubbleTime: 3s;
$bubbleScale: 0.8s; */
:root {
  --bubbleTime: 2s;
  --bubbleScale: 2s;
}
.heart-component {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}
.praise-btn {
  position: relative;
  z-index: 9;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.praise-bubble {
  position: absolute;
  left: 0%;
  transform: translateX(-50%);
  bottom: 38px;
  z-index: 9;
  width: 100px;
  height: 200px;
}
.praise-bubble .bubble {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 32px;
  height: 32px;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-size: 100%;
  transform-origin: bottom;
}

.praise-bubble .b1 {
  background-image: url(../../../assets/img/home/panel4-icon4.png);
  background-size: 100% 100%;
}

.praise-bubble .bl1 {
  animation: bubble1 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
  bubbleBig1 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl2 {
  animation: bubble2 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
    bubbleBig2 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl3 {
  animation: bubble3 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
    bubbleBig1 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl4 {
  animation: bubble4 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
    bubbleBig2 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl5 {
  animation: bubble5 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
  bubbleBig1 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl6 {
  animation: bubble6 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
  bubbleBig3 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl7 {
  animation: bubble7 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
  bubbleBig1 var(--bubbleScale) linear infinite ;
}
.praise-bubble .bl8 {
  animation: bubble8 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
    bubbleBig3 var(--bubbleScale) linear infinite ;
}
.bl9 {
  animation: bubble9 var(--bubbleTime) linear infinite ,
  bubbleY var(--bubbleTime) linear infinite ,
    bubbleBig2 var(--bubbleScale) linear infinite ;
}

@keyframes bubble9 {
  25% {
    margin-left: 10px;
  }
  50% {
    margin-left: 10px;
  }
  100% {
    margin-left: 10px;
  }
}
@keyframes bubble8 {
  25% {
    margin-left: 20px;
  }
  50% {
    margin-left: 20px;
  }
  100% {
    margin-left: 20px;
  }
}
@keyframes bubble7 {
  25% {
    margin-left: 3px;
  }
  50% {
    margin-left: 1px;
  }
  75% {
    margin-left: 2px;
  }
  100% {
    margin-left: 3px;
  }
}
@keyframes bubble6 {
  25% {
    margin-left: -3px;
  }
  50% {
    margin-left: -1px;
  }
  75% {
    margin-left: -2px;
  }
  100% {
    margin-left: -3px;
  }
}
@keyframes bubble5 {
  25% {
    margin-left: 5px;
  }
  50% {
    margin-left: -5px;
  }
  75% {
    margin-left: -10px;
  }
  100% {
    margin-left: -20px;
  }
}
@keyframes bubble4 {
  25% {
    margin-left: -5px;
  }
  50% {
    margin-left: -5px;
  }
  75% {
    margin-left: 20px;
  }
  100% {
    margin-left: 10px;
  }
}
@keyframes bubble3 {
  25% {
    margin-left: -20px;
  }
  50% {
    margin-left: 10px;
  }
  75% {
    margin-left: 20px;
  }
  100% {
    margin-left: -10px;
  }
}
@keyframes bubble2 {
  25% {
    margin-left: 20px;
  }
  50% {
    margin-left: 25px;
  }
  75% {
    margin-left: 10px;
  }
  100% {
    margin-left: 5px;
  }
}
@keyframes bubble1 {
  25% {
    margin-left: -8px;
  }
  50% {
    margin-left: 8px;
  }
  75% {
    margin-left: -15px;
  }
  100% {
    margin-left: 15px;
  }
}
@keyframes bubbleBig1 {
  0% {
    transform: scale(1.2);
  }
  /* 25% {
    transform: scale(1);
  }
  50% {
    transform: scale(.8);
  }
  75% {
    transform: scale(.5);
  } */
  100% {
    transform: scale(0.3);
  }
}
@keyframes bubbleBig2 {
  0% {
    transform: scale(0.9);
  }
  /* 25% {
    transform: scale(.75);
  }
  50% {
    transform: scale(.6);
  }
  75% {
    transform: scale(.5);
  } */
  100% {
    transform: scale(0.3);
  }
}
@keyframes bubbleBig3 {
  0% {
    transform: scale(0.6);
  }
  /* 25% {
    transform: scale(.52);
  }
  50% {
    transform: scale(.46);
  }
  75% {
    transform: scale(.38);
  } */
  100% {
    transform: scale(0.3);
  }
}
@keyframes bubbleY {
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 30px;
  }
  75% {
    opacity: 1;
  }
  100% {
    margin-bottom: 50px;
    opacity: 0;
  }
}
@media (max-width: 989px) {
  .praise-bubble {
    bottom: 20px;
    width: 70px;
    height: 130px;
  }
  .praise-bubble .bubble {
    width: 16px;
    height: 16px;
  }
}
</style>
