<!--
 * @Date: 2023-09-12 14:18:22
 * @LastEditTime: 2023-09-26 12:08:23
 * @Description: 首页侧边导航
-->
<template>
  <div class="nav-bar">
    <div  v-for="(item,index) in list" :key="index">
    <a :class="{'active': active == index, 'deep': !!(active%2), 'hide': item.hide}" :style="{color: active == index?activeColor:''}" :href="'#navBar'+(index-1)"><span v-show="active == index">【</span>{{ item.name }}<span v-show="active == index">】</span></a></div>
  </div>
</template>
<script>
  export default {
    name: 'navBar',
    props: {
      list: {
        type: Array,
        default: () => []
      },
      active: {
        type: Number,
        default: -1
      }
    },
    computed: {
      activeColor() {
        return this.active > -1 ? this.list[this.active].color : 'rgb(255,255,255,.5)'
      }
    }
  }
</script>
<style>
.nav-bar {
  position: fixed;
  left: 10px;
  top: 40%;
  width: 70px;
  font-size: 16px;
  z-index: 10;
  /* background: rgba(255,255,255,0.2); */
  transition: all 0.5s;
  margin-bottom: 8px;
  z-index: 10;
  /* background: ; */
}
.nav-bar a{
  display: block;
  width: 100%;
  color: rgb(255,255,255,.5);
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 8px;
}
.nav-bar a.deep {
  color: #5D5F6C;
}
.nav-bar a.active {
  color:#fff;
}
.nav-bar a.hide {
  position: absolute;
  left: -5000px;
}

</style>