<!--
 * @Date: 2023-09-11 14:10:15
 * @LastEditTime: 2024-07-18 11:28:54
 * @Description: 首页--重构
-->
<template>
  <div class="home-wrap">
    <Viewheader :isHome="true" theme="light" class="header_wrap" />
    <NavBar v-show="showNavBar" ref="navBar" :list="navList" :active="active" />
    <BackTop :theme="!(active % 2) ? 'light' : 'dark'" />
    <div class="gap"></div>
    <!-- 模块1 -->
    <div class="panel-wrap-main" ref="mainPanel">
      <div
        id="navBar-1"
        class="panel panel-main-wrap zindex1"
        ref="panelMainWrap"
      >
        <div class="panel-main">
          <div class="desc">
            <div class="desc-animation">
              <div class="desc-logo">
                <img src="../../assets/img/home/panel1-logo.png" alt="" />
              </div>
              <div>
                <p class="desc-title">AI助力跨境生意增长</p>
              </div>
              <div class="desc-detail">
                <div class="desc-d-app">
                  <span>40+</span>
                </div>
                <div class="desc-d-scene bg-hover-scale"></div>
                <p class="desc-d-text">
                  智能商详、智能客服、智能导购、文案助手、AI设计师、视频理解、视频自动生成等40多种应用，覆盖站外引流、站内营销、店铺安全、直播带货等场景。
                </p>
              </div>
            </div>
            <div class="members">
              200+场景
              <span>实现跨境生意增长</span>
            </div>
          </div>
          <div
            class="panel-main-right panel-main-right_animation"
            :class="{ 'panel-main-right_login': logStatus }"
          >
            <div class="panel-main-right_pos">
              <img
                v-if="!logStatus"
                src="../../assets/img/home/panel1-curve.png"
                class="curve"
              />
              <img
                v-else
                src="../../assets/img/home/panel1-curve2.png"
                class="curve curve-login"
              />
              <!-- <img src="../../assets/img/home/panel1-line.png" class="line mask"> -->
              <img
                ref="circle"
                src="../../assets/img/home/panel1-circle.png"
                class="circle"
              />
            </div>
          </div>
        </div>
        <div class="big-module">
          <div class="big-module_bg">
            <img src="@/assets/img/home/panel1-bg.gif" alt="big-module" />
          </div>
          <div class="big-module-desc">
            <div class="big-module_title">
              "飞天大模型" — <span>跨境电商 多模态 领域</span> 大模型
            </div>
            <p class="big-module_text">
              新一代行业智能大模型，“飞天大模型”作为AIshipgo平台40+能力及产品的<span>技术基座</span>。<br />打造多模态训练、多语言、跨境电商行业新范式。支持多轮对话、具备内容创作、图文互生等能力。
            </p>
          </div>
        </div>
        <div ref="importExport" class="import-export" @scroll.prevent>
          <div v-for="(item, index) in importExportList">
            <dl :class="{ mt40: index > 0 }" class="import-export_import">
              <dt>Input</dt>
              <dd>{{ item.importText }}</dd>
            </dl>
            <img v-if="item.img" :src="item.img" alt="" />
            <dl v-if="item.exportText" class="import-export_export">
              <dt>Output</dt>
              <dd>
                {{ item.exportText }}
              </dd>
            </dl>
          </div>
        </div>
        <p class="panel-main-info">
          目前已有系列模型版本:Feitian-7B，Feitian-13B，Feitian-7B-Chat
        </p>
        <div class="panel-main_bottombg">
          <span>产品应用</span>
          <img src="../../assets/img/home/panel1-arrow.png" />
        </div>
      </div>
    </div>
    <!-- 人 -->
    <div class="panel-wrap-person">
      <div id="navBar0" class="panel person" ref="person">
        <div class="common-title">
          <h3>高效引流吸客</h3>
          <div class="common-text">
            <p>
              专注海外跨境社媒营销，提供跨境电商在SNS社媒（
              <img src="../../assets/img/home/icon_facebook.png" class="icon" />
              <img src="../../assets/img/home/icon_ins.png" class="icon" />
              <img src="../../assets/img/home/icon_tiktok.png" class="icon" />
              Facebook/ Instagram/Tiktok等）、SEO（
              <img src="../../assets/img/home/icon_google.png" class="icon" />
              <img src="../../assets/img/home/icon_shopping.png" class="icon" />
              谷歌网⻚搜索/shopping等）引流
              <img src="../../assets/img/home/panel2-right.png" class="icon" />
              <span> A</span><span>I</span><span>解</span><span>决</span
              ><span>方</span><span>案。</span>
            </p>
            <p>
              解决投放品确定、商品渠道准入、素材优化及生成
              <img
                src="../../assets/img/home/panel2-ball.png"
                class="icon ball"
              />
              投放效果反馈等
              <img
                src="../../assets/img/home/panel2-right.png"
                class="icon"
              /><span> 全</span><span>链</span><span>路</span><span>业</span
              ><span>务</span><span>问</span
              ><span>题。</span>提升流量规模及精准性。
            </p>
          </div>
        </div>
        <div class="common-two-column common-two-column_imgleft">
          <div class="common-two-column_img">
            <img src="../../assets/img/home/ai.png" />
          </div>
          <div class="common-two-column_text">
            <h5 class="sec-title">社媒视频AI合成</h5>
            <p class="sec-text">
              合成带有语音字幕介绍及数逼真数字形象的短视频，满足社媒引流、电商商详呈现、私域运营等场景。
            </p>
            <ul>
              <li>
                <div>
                  <img src="../../assets/img/home/GO.png" alt="" />素材准备
                </div>
                <p>
                  商品图文素材、海量视频素材库、生成卖点介绍/属性、背景音乐/自动海报…
                </p>
              </li>
              <li>
                <div>
                  <img src="../../assets/img/home/panel2-ball.png" alt="" />AI
                  合成
                </div>
                <p>图文/视频与介绍语义联动、模块/展示序列调度 ...</p>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/rectangle.png"
                    alt=""
                  />差异化场景输出
                </div>
                <p>时长、尺寸 ...</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="person-media">
          <h5 class="sec-title">社媒自动化投放</h5>
          <img src="../../assets/img/home/panel2_ball3.png" alt="" />
          <img src="../../assets/img/home/panel2_ball2.png" alt="" />
          <p class="sec-text">
            基于自身跨境电商平台，真实业务场景抽象出的方案，并得到成功数据反馈；方案闭环根据投放数据反馈，自迭代，摒弃“经验化”运营；投放品类选择、视频图文素材收集+混剪处理、跳转站内+承接、粉丝互动等自动化处理。
          </p>
        </div>
        <div class="person-flow">
          <div class="earpods">
            <img src="@/assets/img/home/earpods.png" />
            <img src="@/assets/img/home/dot.png" class="dot" />
          </div>
          <img src="@/assets/img/home/panel2_bg.png" class="arrow" />
          <img src="@/assets/img/home/panel2_bg2.png" class="earpods2" />
          <div class="flow-top">
            <div class="flow-top_circle flow_circle">
              <ul>
                <li>
                  <div class="flow-title">投放品确定</div>
                  <p class="flow-text">流量商品选择<br />流量商品词抽取+挖掘</p>
                </li>
                <li>
                  <div class="flow-title">投放素材收集</div>
                  <p class="flow-text">图文视频素材采集<br />素材清洗</p>
                </li>
                <li>
                  <div class="flow-title">投放素材生成</div>
                  <p class="flow-text">图文post生成<br />引流视频生成</p>
                </li>
                <li>
                  <div class="flow-title flow-title-light">
                    投放效果数据反馈
                  </div>
                  <p class="flow-text flow-text-light">
                    基于引流效率，动态调整商品
                  </p>
                </li>
                <li>
                  <div class="flow-title flow-title-light">跳转站内+承接</div>
                  <p class="flow-text flow-text-light">
                    投放效果数据统计
                    <br />投放跳链优化<br />投放社群维护(自动回复)
                    <br />流量画像理解<br />引流视频生成
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="flow-bottom">
            <div class="flow-bottom_circle flow_circle">
              <img src="@/assets/img/home/panel2_bg1.png" class="material" />
              <img
                src="@/assets/img/home/panel2-comments1.png"
                class="comments comments1"
              />
              <img
                src="@/assets/img/home/panel2-comments2.png"
                class="comments comments2"
              />
              <img
                src="@/assets/img/home/panel2-comments3.png"
                class="comments comments3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 货 -->
    <div class="panel-wrap-product zindex1">
      <div id="navBar1" class="panel product" ref="product">
        <div class="panel-wrap-product_bg"></div>
        <div class="common-title common-title-light">
          <h3>商品价值<br />精准/多元化展示</h3>
          <div class="common-text">
            <p>
              专注跨境商品信息表达力提升
              <img src="@/assets/img/home/panel3-logo1.png" class="icon" />
              ，在商品上架阶段给出默认建议值。同时针对已上架商品给出商品竞争力分析及优化建议
              <img src="@/assets/img/home/panel3-logo2.png" class="icon hot" />
              。
            </p>
            <p>
              商品信息优化系列模型帮助您提升商品在平台上的流量转化和站外投流引流效率，
              <a class="underline-fade-in"
                >优化商品竞争力，提升销售效率。<a
                  class="underline-fade-in_insert"
                ></a
              ></a>
            </p>
          </div>
        </div>
        <div class="product-update">
          <div class="product-update_status product-update_on bg-hover-scale">
            <!-- <p>商品上架-智能商详</p> -->
          </div>
          <div
            class="product-update_status product-update_after bg-hover-scale"
          >
            <!-- <p>上架后-商品诊断</p> -->
          </div>
        </div>
        <div class="product-survice">
          <!-- <div src="@/assets/img/home/panel3-shadow.png" class="product-shadow"></div> -->
          <div class="product-survice_left">
            <h5 class="sec-title sec-title-light">商品信息优化服务</h5>
            <p class="sec-text sec-text-light">
              商品上架-智能商详：商品标题优化、卖点生成建议、长短描生成建议、属性建议、首图建议、优化、FAQ生成建议…
              <br />上架后-商品诊断：竞争力多维分析、新品识别、商品信息诊断…
            </p>
          </div>
          <div class="product-survice_right">
            <img src="@/assets/img/home/panel3-bg3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 场 -->
    <div class="panel-wrap-market zindex1">
      <div id="navBar2" class="panel market" ref="market">
        <div class="common-title">
          <h3>站内营销</h3>
          <div class="common-text">
            <p>
              专注海外跨境
              <img src="@/assets/img/home/panel4_logo.png" class="icon" />
              社媒营销，提供一整套用户生命周期管理 (
              系列模型：流量LTV/用户增益/个性化优惠券/流失预警等模型 )
              、站内商品/权益分发 ( 系列产品：主动营销/导购BOT/数字人直播等 )
              营销解决方案。助力客户站内用户转化提升。
            </p>
          </div>
        </div>
        <div class="common-two-column common-two-column_textleft">
          <div class="common-two-column_text">
            <h5 class="sec-title">电商主动营销/导购BOT</h5>
            <p class="sec-text">
              基于“对话式主动营销/导购bot”，改变“商品信息/价值被动等待买家发现”
              的现状，为买家提供更高效、价值更个性主动触达的购物体验；通过
              bot主动需求采集、商机共享、多模态需求理解+商品推荐、导购营销话术等产品手段，以提升买家购物转化。
            </p>
            <ul>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel4_icon8.png"
                    alt=""
                  />全局统一化入口+需求激发
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel4_icon9.png"
                    alt=""
                  />营销导购+推荐+价值传达
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel4_icon7.png"
                    alt=""
                  />营销活动主动触达+需求激发
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel4_ico4.png"
                    alt=""
                  />商机采集+共享/转发
                </div>
              </li>
            </ul>
          </div>
          <div class="common-two-column_img">
            <img src="../../assets/img/home/panel4_video.png" />
          </div>
        </div>
        <div class="livesale">
          <div class="livesale_desc">
            <h5 class="sec-title sec-title-light">数字人直播带货</h5>
            <p class="sec-text sec-text-light">
              基于数字人直播商品介绍，24H全天候多语直播互动，解决跨境电商跨时区运营、外语交流、主动互动等业务问题。提升生意效率、促进成单。
            </p>
          </div>
          <ul class="bullets">
            <li>
              <img src="@/assets/img/home/panel4-bullet1.png" alt="" />
              <!-- <div class="fans-tag">铁粉26</div>
              <span>小玉 </span> 虚拟主播真酷呀 -->
            </li>
            <li>
              <img src="@/assets/img/home/panel4-bullet2.png" alt="" />
            </li>
            <li>
              <img src="@/assets/img/home/panel4-bullet3.png" alt="" />
            </li>
            <li>
              <img src="@/assets/img/home/panel4-bullet4.png" alt="" />
            </li>
          </ul>
          <ul class="function">
            <li>
              <div class="function-title">多语言/音色、多形象数字人直播</div>
              <div class="voice-type">
                <img src="@/assets/img/home/panel4_icon1.png" alt="" />
                <img src="@/assets/img/home/panel4_icon2.png" alt="" />
                <img src="@/assets/img/home/panel4_icon3.png" alt="" />
                <img src="@/assets/img/home/panel4_icon4.png" alt="" />
                <img src="@/assets/img/home/panel4_icon5.png" alt="" />
                <img src="@/assets/img/home/panel4_icon6.png" alt="" />
              </div>
            </li>
            <li>
              <div class="function-title">逼真面部、口型、肢体动作</div>
            </li>
            <li>
              <div class="function-title">直播介绍脚本AI生成</div>
            </li>
            <li>
              <div class="function-title">有互动、用户问题AI回复</div>
            </li>
            <li>
              <div class="function-title">产品介绍生动有趣</div>
            </li>
            <li>
              <div class="function-title">
                可能无限♾️<br />
                <span>你想要的全都有</span>
              </div>
            </li>
          </ul>
          <div class="shop-bar">
            <img src="@/assets/img/home/panel4-icon.png" class="cart" alt="" />
            <div class="shop-input">say something</div>
            <div class="shop-icon">
              <img
                class="menu"
                src="@/assets/img/home/panel4-icon1.png"
                alt=""
              />
            </div>
            <div class="shop-icon">
              <img
                class="phone"
                src="@/assets/img/home/panel4-icon2.png"
                alt=""
              />
            </div>
            <div class="shop-icon">
              <img
                class="share"
                src="@/assets/img/home/panel4-icon3.png"
                alt=""
              />
            </div>
            <div class="shop-icon">
              <img
                class="heart"
                src="@/assets/img/home/panel4-icon4.png"
                alt=""
              />
              <!-- <div class="bubble b1 bl1"></div> -->
              <Heart ref="handleHeart"></Heart>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- 安全 -->
    <div class="panel-wrap-security zindex1">
      <div id="navBar3" class="panel security" ref="security">
        <div class="panel-wrap-security_bg"></div>
        <div class="common-title common-title-light">
          <h3>店铺安全保驾护航</h3>
          <div class="common-text">
            <p>
              专注
              <img src="../../assets/img/home/panel2-right.png" class="icon" />
              <span> 跨</span><span>境</span><span>内</span><span>容</span
              ><span>⻛</span><span>控</span><span>审</span
              ><span>核，</span>提供
              <img
                src="../../assets/img/home/panel2-right.png"
                class="icon"
              /><span> 商</span><span>品</span><span>侵</span><span>权</span
              ><span>违</span><span>规</span><span>识</span><span>别</span
              ><span>方</span
              ><span>案，</span>包括文本、图像和视频的识别，识别跨境易侵权品牌
              (特别对变形/涂抹/遮挡等进行了优化)
              、涉黄、涉政、涉恐、异常商品等，降本增效，保障平台/店铺安全。
            </p>
          </div>
        </div>
        <div class="common-two-column common-two-column_imgleft">
          <div class="common-two-column_img">
            <img src="../../assets/img/home/panel5-video.png" />
          </div>
          <div class="common-two-column_text">
            <h5 class="sec-title">商品风控系列模型</h5>
            <!-- <p class="sec-text">跨境品牌侵权风控（变形/遮挡等）  </p> -->
            <ul>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel5_icon1.png"
                    alt=""
                  />跨境品牌侵权风控（变形/遮挡等）
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel5_icon2.png"
                    alt=""
                  />色情商品风控
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel5_icon3.png"
                    alt=""
                  />跨境社恐/涉政/涉暴等风控
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel5_icon4.png"
                    alt=""
                  />拉线下/广告诱导信息风控
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="../../assets/img/home/panel5_icon5.png"
                    alt=""
                  />更多...
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-survice">
          <!-- <div src="@/assets/img/home/panel3-shadow.png" class="product-shadow"></div> -->
          <div class="product-survice_left">
            <h5 class="sec-title sec-title-light">
              全天候增量、存量信息风控巡检
            </h5>
            <p class="sec-text sec-text-light">
              文本识别、图片识别、视频识别...
            </p>
          </div>
          <div class="product-survice_right">
            <img src="@/assets/img/home/panel5-bg.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 能力矩阵 -->
    <div class="cpacity-wrap">
      <div id="navBar4" class="cpacity panel">
        <div class="common-title">
          <h3>能力矩阵</h3>
        </div>
        <ul>
          <li
            v-for="(item, index) in cpacityList"
            :key="index"
            :style="{ background: item.bgColor }"
          >
            <div class="cpacity-icon">
              <div class="cpacity-icon_bg"></div>
              <img :src="item.img" />
            </div>
            <div class="cpacity-cont">
              <h5 class="sec-title sec-title-light">{{ item.title }}</h5>
              <p class="sec-text sec-text-light">{{ item.text }}</p>
            </div>
            <div class="cpacity-page"># 0{{ index + 1 }}</div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Viewheader from '../../components/viewheader/viewheader.vue';
import NavBar from './components/navBar.vue';
import Footer from '../../components/foot/footer.vue';
import Heart from './components/heart.vue';
import BackTop from './components/backTop.vue';
import { mapGetters } from 'vuex';
import '../../assets/css/public.css';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// 进出口文案
const importExport = [
  {
    import: `Based on the product title in [ ], generate 5 selling point descriptions of the product.
 [Mermaid Red Feathers Evening Dress Slim Party Gown Long Sleeves Prom Dresses vestido de festa longo New Arrival3394218]`,
    export: `Red feathers: The Mermaid red feathers evening dress is adorned with vibrant red feathers, giving it a luxurious and regal look perfect for any party or special occasion.
Slim fit: The dress has a slim fit, which accentuates the figure and makes you look beautiful and confident.
Long sleeves: The dress has long sleeves, which add a touch of elegance and grace to the design.
Fit well: The dress has a well-fitting design, which ensures a comfortable and flattering fit for your body.
Versatile: The Mermaid red feathers evening dress is versatile and can be worn for various occasions, such as parties, weddings, and formal events.`,
  },
  {
    import: `According to this clothing product picture, please list the attributes such as output color, material, style in detail.`,
    export: `The color of the shirt in the picture is olive green. The material of the shirt appears to be a cotton blend. The style of the shirt is a casual, t - shirt. The logo on the shirt appears to be a silkscreened image of two dogs. The text on the shirt reads “gang dog gang dog” in white lettering. The shirt appears to be a unisex size and is not labeled as a specific brand.`,
  },
];

export default {
  components: {
    Viewheader,
    NavBar,
    Footer,
    Heart,
    BackTop,
    // Footering
  },

  data() {
    return {
      media: null,
      currentIndex: 0,
      active: -1,
      showNavBar: false,

      // 输入输出模块文本
      importExportList: [
        {
          importText: importExport[0].import,
          exportText: '',
        },
      ],
      timer: null,
      screenHeight: 0,
      navList: [
        {
          name: 'first',
          color: '#fff',
          hide: true,
        },
        {
          name: '人',
          color: '#6C38E0',
        },
        {
          name: '货',
          color: '#fff',
        },
        {
          name: '场',
          color: '#6C38E0',
        },
        {
          name: '安全',
          color: '#fff',
        },
        {
          name: '能力矩阵',
          color: '#fff',
          hide: true,
        },
      ],
      cpacityList: [
        {
          title: 'NLP文本处理',
          text: '依托大模型能力，提供多语翻译、自动生成商品详情、关键信息提取、文章生成、情感分析、生成式问答系统等功能及文本相似解决方案',
          bgColor: '#6C38E0',
          img: require('../../assets/img/home/cpacity-1.png'),
        },
        {
          title: 'CV图像视频处理',
          text: '全⾯的图像理解和处理能力，包括品牌⾊情识别等商品安全场景，抠图、水印涂抹、视频⽣成等能力，具有图像搜索功能，适用于拍照搜同款、相似商品推荐等场景。',
          bgColor: '#232137',
          img: require('../../assets/img/home/cpacity-2.png'),
        },
        {
          title: 'ML数据挖掘及增长',
          text: '敏锐精准的⼤数据分析挖掘，应⽤于智能优惠券、流失预警和增益分析等站内营销场景，助力转化升级。',
          bgColor: '#5338E0',
          img: require('../../assets/img/home/cpacity-3.png'),
        },
        {
          title: '多模态图文理解',
          text: '打造跨模态领域模型，拥有图文交互能力，推进⽣成式AI解决⽅案落地应⽤。',
          bgColor: '#3F4265',
          img: require('../../assets/img/home/cpacity-4.png'),
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['logStatus']),
  },

  watch: {
    active: {
      immediate: true,
      handler(n) {
        this.$nextTick(() => {
          if (n == 0) {
            document
              .querySelector('.panel-main-wrap')
              ?.classList.add('zindex1');
            document
              .querySelector('.panel-main_bottombg')
              ?.classList.remove('hide');
            document.querySelector('.person')?.classList.remove('zindex1');
          } else {
            document
              .querySelector('.panel-main-wrap')
              ?.classList.remove('zindex1');
            document
              .querySelector('.panel-main_bottombg')
              ?.classList.add('hide');
            document.querySelector('.person')?.classList.add('zindex1');
          }
        });
      },
    },
  },

  mounted() {
    this.hideNavBar();
    this.media = gsap.matchMedia();
    this.media.add('(min-width: 990px)', () => {
      this.initTrigger();

      this.initInlineImg();
      this.removeAnimation();
      this.handleClicle();
      this.initImgScale();
      this.initComments();
      this.initBullets();
      // this.seccontText({el: '.bullets img'});
      this.seccontText({ el: '.sec-title' });
      this.seccontText({ el: '.sec-text', delay: 0.2 });
      this.seccontText({
        el: '.common-two-column_text ul',
        delay: 0.8,
        from: {
          opacity: 0,
          transform: 'translateY(10%)',
        },
      });
    });
    this.$nextTick(() => {
      this.initNavBar();
      window.addEventListener('scroll', this.hideNavBar);
      // 调用进出口模块的文字特效
      this.initEffectTextInput();
    });
  },

  methods: {
    // 调用进出口模块的文字特效
    initEffectTextInput() {
      gsap.to('.import-export', {
        duration: 1,
        ease: 'power1.in',
        scrollTrigger: {
          trigger: '.import-export',
          scrub: true,
          start: 'top 500px',
          onEnter: this.handleEffectTextInput,
        },
      });
    },
    handleEffectTextInput() {
      if (this.textProcess) return;
      this.textProcess = true;
      if (this.importExportList.length === 2) return;
      const delay = 70;
      this.effectTextInput({
        texts: importExport[0].export,
        delay,
        container: this.$refs.importExport,
        callback: ({ val, end }) => {
          if (!end) {
            this.importExportList[0].exportText = val;
          } else {
            const timer = setTimeout(() => {
              this.importExportList.push({
                importText: importExport[1].import,
                exportText: '',
                img: require('../../assets/img/home/panel1-dog.png'),
              });
              this.$refs.importExport &&
                this.$refs.importExport.scrollTo({
                  top: 10000,
                  left: 0,
                });

              this.effectTextInput({
                texts: importExport[1].export,
                delay,
                container: this.$refs.importExport,
                callback: ({ val, end }) => {
                  if (!end) {
                    this.importExportList[1].exportText = val;
                  } else {
                    this.importExportList = [];
                    this.importExportList.push({
                      importText: importExport[0].import,
                      exportText: '',
                    });
                    // console.log(this.importExportList)
                    this.$nextTick(() => {
                      this.textProcess = false;
                      this.handleEffectTextInput();
                    });
                  }
                },
              });

              clearTimeout(timer);
            }, delay * 8);
          }
        },
      });
    },
    // 动画效果-文字输入
    effectTextInput({ texts, delay = 100, container, callback }) {
      // console.log('effectTextInput----------------------------------=-')
      texts = texts.split(' ');
      const len = texts.length;
      let result = ``;
      if (len) {
        let index = 0;
        this.timer = setInterval(() => {
          if (delay == 20) {
          }
          // console.log('effectTextInput----------',this.timer,index >= len);

          result += texts[index] + ' ';
          index++;
          if (callback && typeof callback === 'function') {
            callback({ val: result, end: index >= len });
          }
          container &&
            container.scrollTo({
              top: 10000,
              left: 0,
            });
          if (index >= len) {
            clearInterval(this.timer);
            // this.timer = null;
            return;
          }
        }, delay);
      }
    },
    // 首页第一屏不展示导航
    hideNavBar() {
      this.panel1Height = this.$refs.mainPanel.offsetHeight;
      this.screenHeight =
        this.screenHeight ||
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > scrollTop) {
        this.scrollDirection = 'up';
      } else {
        this.scrollDirection = 'down';
      }
      this.scrollTop = scrollTop;
      this.showNavBar =
        this.scrollTop > this.panel1Height - this.screenHeight * 0.3;
    },
    removeAnimation() {
      const animated = document.querySelector('.panel-main-right');
      console.log(animated);
      animated.addEventListener('animationend', () => {
        // console.log("Animation ended");
        animated.classList.remove('panel-main-right_animation');
      });
    },
    initTrigger() {
      const panels = gsap.utils.toArray('.panel');
      panels.forEach((section, index) => {
        let tween = gsap.fromTo(
          section,
          {
            autoAlpha: 1,
            scale: 1,
            duration: 1,
            paused: true,
          },
          {
            scale: 0.9,
            duration: 1,
            paused: true,
            autoAlpha: 0,
            scrollTrigger: {
              pin: true,
              pinSpacing: false,
              trigger: section,
              scrub: true,
              // start: 'bottom 60%',
              start: 'bottom center',
              // end: "bottom center",
              // toggleClass: 'active', // start - end 阶段进行添加，不符合条件删除
              onLeave: () => {
                section.classList.remove('active');
              },
              onEnter: () => {},
              onUpdate: () => {
                this.currentIndex = index;
              },
              onToggle: (self) => {},
              onEnterBack: () => {},
            },
          },
        );
        index || tween.progress(1); // the first tween should be at its end (already faded/scaled in)
      });
    },
    initNavBar() {
      let links = gsap.utils.toArray('.nav-bar a');
      links.forEach((a, index) => {
        let element = document.querySelector(a.getAttribute('href')),
          linkST = ScrollTrigger.create({
            trigger: element,
            start: '60px 20%',
          });
        ScrollTrigger.create({
          trigger: element,
          start: 'top center',
          end: 'bottom center',
          onToggle: (self) => {
            this.active = index;
          },
        });
        a.addEventListener('click', (e) => {
          e.preventDefault();
          gsap.to(window, {
            duration: 1,
            scrollTo: linkST.start,
            overwrite: 'auto',
          });
        });
      });
      this.active = 0;
    },
    initInlineImg() {
      const duration = 1;
      const triggers = ['person', 'product', 'market', 'security'];
      triggers.forEach((panel) => {
        let collectEl = [];
        let el = document.querySelectorAll(`.${panel} .common-text p`);
        [...el].forEach((item) => {
          collectEl.push(...item.children);
        });
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: this.$refs[panel],
            // scrub :false,
            start: '-300px top',
            end: '800px 90%',
            scrub: true,
            // markers: true,
          },
        });
        collectEl.forEach((item) => {
          const tagName = item.tagName.toLowerCase();
          const className = item.className;
          if (tagName === 'img') {
            tl.fromTo(
              item,
              {
                width: 0,
                // duration: .9,
              },
              {
                width: 'auto',
                duration: 1,
                ease: 'power1.out',
              },
            );
          } else if (tagName === 'span') {
            tl.to(item, {
              color: '#6C38E0',
              duration: 0.1,
            });
          } else if (className === 'underline-fade-in') {
            // console.log('`.${panel} .underline-fade-in_insert`',`.${panel} .underline-fade-in_insert`);
            tl.to(`.${panel} .underline-fade-in_insert`, {
              clipPath: 'inset(0 0% 0 0)',
              duration: 1,
            });
          }
        });
        // tl.to(`.${panel} .icon`, {
        //     width: 'auto',
        //     duration,

        //   }).to(`.${panel} .underline-fade-in_insert`, {
        //     clipPath: 'inset(0 0% 0 0)',
        //     duration
        //   }).to(`.${panel} .scroll-to-show`, {
        //     opacity:1,
        //     y: 0,
        //     duration
        //   })

        // this.initWordGradient(panel, '#6C38E0', duration)
      });
    },
    handleClicle(el) {
      gsap.to(this.$refs.circle, {
        rotate: 45,
        duration: 1,
        ease: 'power1.in',
        scrollTrigger: {
          trigger: '.panel-main',
          scrub: true,
          start: 'top top',
          end: 'center 30%',
          // markers: true,
        },
      });
    },
    initWordGradient(el, color, delay) {
      const wordsTL = gsap.timeline(
        {
          scrollTrigger: {
            // pin: true,
            // pinSpacing:false,
            trigger: this.$refs[el],
            start: '-15% top',
            end: '10% 20%',
            // scrub: true
          },
        },
        +300,
      );
      const chars = gsap.utils.toArray(`.${el} .common-text span`);
      // console.log('chars',chars);
      chars.forEach((item) => {
        wordsTL.to(item, {
          color: color,
          duration: 0.1,
        });
      });
    },
    initImgScale() {
      const imgs = gsap.utils.toArray('.el-hover-scale');
      imgs.forEach((img) => {
        gsap.fromTo(
          img,
          {
            scale: 1.2,
            // duration: .9,
          },
          {
            scale: 1,
            delay: 0.6,
            // rotate: 45,
            duration: 1,
            ease: 'power1.in',
            scrollTrigger: {
              trigger: img,
              // scrub: true,
              // marker: true,
              start: '-300px top',
              end: '100px 30%',
            },
          },
        );
      });
    },
    initComments() {
      const comments = gsap.utils.toArray('.comments');
      // console.log('comments',comments);
      const effets = [
        {
          transform: 'translateX(-50%)',
        },
        {
          transform: 'translateX(50%)',
        },
        {
          transform: 'translateX(50%)',
        },
      ];
      let delay = 0;
      comments.forEach((comment, index) => {
        const effet = effets[index];
        gsap.fromTo(
          comment,
          {
            opacity: 0,
            ...effet,
          },
          {
            transform: 'translateX(0)',
            // rotate: 45,
            duration: 0.5,
            opacity: 1,
            delay: (delay += 0.3),
            ease: 'power1.in',
            scrollTrigger: {
              trigger: comment,
              // scrub: true,
              marker: true,
              // start: '-40% top',
              end: 'top 80%',
            },
          },
        );
      });
    },
    initBullets() {
      const bullets = gsap.utils.toArray('.bullets img');
      let delay = 0;
      bullets.forEach((bullet, index) => {
        gsap.fromTo(
          bullet,
          {
            opacity: 0,
            transform: 'translateY(150%)',
          },
          {
            transform: 'translateY(0)',
            // rotate: 45,
            duration: 0.7,
            opacity: 1,
            delay: (delay += 0.3),
            ease: 'power1.in',
            scrollTrigger: {
              trigger: bullet,
              // scrub: true,
              marker: true,
              // start: 'top 50%',
              end: 'top 90%',
            },
          },
        );
      }, 4);
    },
    // 全文同元素上移进入
    seccontText({
      el,
      delay = 0,
      duration = 0.5,
      from = {
        opacity: 0,
        transform: 'translateY(50%)',
      },
      to = {
        transform: 'translateY(0)',
        opacity: 1,
      },
    }) {
      const titles = gsap.utils.toArray(el);
      titles.forEach((titles, index) => {
        gsap.fromTo(titles, from, {
          ...to,
          // rotate: 45,
          duration,
          delay,
          ease: 'power1.in',
          scrollTrigger: {
            trigger: titles,
            // scrub: true,
            marker: true,
            start: '-300px 40%',
            // end: 'top top',
          },
        });
      });
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.hideNavBar);
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: normal;
}
ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
li {
  list-style-type: none;
  text-align: center;
}
.gap {
  height: 80px;
}

/* 背景hover放大120% */
.bg-hover-scale {
  background-size: 100% auto;
  transition: all 0.3s;
}
.bg-hover-scale:hover {
  background-size: 120% auto;
}
/* 元素hover放大120% */
.el-hover-scale {
  /* transition: all .3s; */
}
.el-hover-scale:hover {
  transform: scale(1.2);
}
/* 下方滑入 */
.bottom-slide-in {
  transform: translateY(50%);
  transition: all 0.4s;
  opacity: 0;
}

.home-wrap {
  min-height: 200vh;
}
.panel {
  width: 1280px;
  height: fit-content;
}

/* 首屏 ----------*/
.panel-main-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 104px;
  z-index: 0 !important;
}
.zindex1 {
  position: relative;
  z-index: 1 !important;
}
.panel-main {
  width: 1280px;
  display: flex;
  position: relative;
  /* background: #100029; */
  padding-top: 60px;
  padding-bottom: 80px;
  margin: 0 auto 0;
}
.panel-wrap-main {
  /* background: #100029; */
  background: linear-gradient(180deg, #100029 1200px, #000 1500px);
  padding-top: 80px;
  margin-top: -80px;
}
.desc {
  position: relative;
  width: 51%;
  padding-top: 9%;
}
.desc-animation {
  transform: translateY(10%);
  opacity: 0;

  animation: bottomSlideIn ease-out 0.9s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}
.desc-logo {
  width: 94%;
  height: auto;
}
.desc-logo img {
  width: 100%;
}
.desc-title {
  font-size: 59px;
  margin: 17px 0 0;
  color: #fff;
}
@keyframes bottomSlideIn {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.desc-detail {
  display: flex;
  margin-top: 83px;
}
.desc-d-app {
  position: relative;
  width: 7.4%;
  height: 0;
  padding-bottom: 7.4%;
  border-radius: 428px;
  background: url(../../assets/img/home/panel1-app.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  transition: all 0.3s;
  /* overflow: hidden; */
}
.desc-d-app span {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  color: rgba(240, 242, 245, 1);
  /* background: rgba(0, 0, 0, .5); */
  font-size: 16px;
  align-items: center;
  justify-content: center;
}
.desc-d-app::after {
  content: '';
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  width: 1px;
  height: 30px;
}
.desc-d-app:hover {
  background-size: 230% 230%;
}
.desc-d-scene {
  width: 18.4%;
  height: 0;
  padding-bottom: 7.4%;
  background-image: url(../../assets/img/home/panel1-scene.gif);
  background-repeat: no-repeat;
  margin-left: 3.8%;
  border-radius: 100px;
  background-position: center top;
}
.desc-d-text {
  width: 46.2%;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 18px;
  margin-left: 3.5%;
}
.members {
  position: absolute;
  font-size: 12px;
  color: #fff;
  left: 0;
  bottom: 0;
  padding-bottom: 1.2%;
}
.members span {
  display: block;
  width: fit-content;
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid #fff;
  padding-top: 1.2%;
  margin-top: 1.2%;
}

.panel-main-right {
  position: relative;
  /* flex: 1; */
  width: 49%;
  height: 0;
  padding-bottom: 54.7%;
  background-image: url(../../assets/img/home/panel1-person.png);
  background-size: 954px auto;
  /* 1144 */
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 10px;
  /* margin-top: 80px; */
  transition: all 0.3s;
  background-position: -95px bottom;
  /* background-position: 29% bottom; */
}
.panel-main-right_login {
  overflow: unset;
}
.panel-main-right_animation {
  background-size: 1144px auto;
  transition: all 0.3s;
  animation: bgScale linear 0.3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  background-position: -210px bottom;
  /* background-position: 40% bottom; */
}
.panel-main-right:hover {
  background-size: 1144px auto;
  /* background-position: 40% bottom; */
  background-position: -210px bottom;
}
@keyframes bgScale {
  100% {
    background-size: 954px auto;
    /* background-position: 29% bottom; */
    background-position: -95px bottom;
  }
}
.panel-main-right_pos {
  margin-top: -65px;
}
.panel-main-right_login .panel-main-right_pos {
  margin-top: -129px;
}
.panel-main-right .curve {
  /* position: absolute; */
  /* margin-top: -172px; */
  width: 100%;
  height: auto;
}
.panel-main-right .curve.curve-login {
  /* margin-top: -129px; */
}
.panel-main-right .line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-43%, -56%);
  /* position: absolute; */
  /* margin-top: -172px; */
  width: 245%;
  height: auto;
}
.line.mask {
  -webkit-mask-image: url(../../assets/img/home/panel1-curve.png);
  mask-image: url(../../assets/img/home/panel1-curve.png);
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.panel-main-right .circle {
  position: absolute;
  right: 26.7%;
  top: 28.3%;
  width: 21.7%;
  height: auto;
}
.big-module {
  position: relative;
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
  /* padding-top: 10%; */
}
.big-module_bg {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}
.big-module_bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.big-module_bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 44%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #100029 0%, rgba(16, 0, 41, 0) 100%);
}
.big-module_bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 56%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000, rgba(16, 0, 41, 0));
}
.big-module-desc {
  position: absolute;
  z-index: 1;
  top: 35%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  width: fit-content;
  font-family: PingFangSC-Semibold;
}
.big-module_title {
  font-size: 59px;
  white-space: nowrap;
}
.big-module_text {
  font-size: 24px;
  margin-top: 24px;
  text-align: center;
}
.big-module-desc span {
  color: #6c38e0;
}

.import-export {
  position: relative;
  z-index: 2;
  width: 1280px;
  height: 400px;
  overflow-y: auto;
  margin: auto;
  padding: 24px;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0.64) 18%,
    rgba(255, 255, 255, 0.48) 80%
  );
  /* background: linear-gradient(112deg, #a3a3a3 18%, #7a7a7a 80%); */
  border-radius: 16px;
  color: #000;
  margin-top: -145px;
  font-size: 16px;
  box-sizing: border-box;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.import-export dt {
  position: relative;
  text-indent: 12px;
  font-weight: 900;
  margin-bottom: 12px;
}
.import-export dd {
  /* height: 85px; */
  white-space: pre-line;
  overflow-y: auto;
}
.import-export img {
  width: 178px;
  margin-top: 16px;
}
.import-export::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.import-export_export {
  margin-top: 40px;
}
.import-export_import dt {
  color: #ff8645;
}
.import-export_export dt {
  color: #5c24d8;
}
.mt40 {
  margin-top: 40px;
}
.import-export dt::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0px;
  background: #ff8645;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
}
.import-export_export dt::before {
  background: #5c24d8;
}
.panel-main-info {
  font-size: 14px;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-top: 24px;
}
.panel-main_bottombg {
  position: absolute;
  width: 152px;
  height: 98px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 53px);
  background-image: url(../../assets/img/home/panel1-bg2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 11;
  transition: all 0.2s;
  font-size: 14px;
  color: #000108;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  box-sizing: border-box;
}
.panel-main_bottombg img {
  width: 16px;
  height: 10px;
  margin-top: 9px;
  animation: slideGuide 0.9s infinite ease-out;
}
@keyframes slideGuide {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(7px);
  }
}
.panel-main_bottombg.hide {
  /* visibility: hidden; */
  opacity: 0;
  /* bottom: 80px; */
  transform: translate(-50%, 10px);
}
/* 模块通用头部 */
.common-title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.common-text {
  width: 66%;
}
.common-title p {
  width: 100%;
  color: #000108;
  font-size: 24px;
  margin-bottom: 24px;
}
.common-title p div {
  display: inline-block;
}
.common-title .icon {
  height: 24px;
  vertical-align: top;
}
.common-title .ball {
  /* width: 50px; */
}

.common-title h3 {
  font-size: 40px;
  color: #000108;
}
.common-title-light p,
.common-title-light h3 {
  color: #fff;
}

.sec-text {
  font-size: 14px;
  color: rgba(93, 95, 108, 1);
}
.sec-title {
  color: #000108;
  font-size: 24px;
}
.sec-title-light {
  color: #fff;
}
.sec-text-light {
  color: rgba(255, 255, 255, 0.7);
}

/* 模块通用两栏布局 */
.common-two-column {
  position: relative;
  padding-bottom: 49.5%;
  display: flex;
  width: 100%;
  margin-top: 80px;
  border-radius: 13px;
  overflow: hidden;
}
.common-two-column_text {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #fff;
  background-image: url(../../assets/img/home/ai_bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom left;
  padding: 80px 40px 0;
  box-sizing: border-box;
}
.common-two-column_text h5 {
  margin-bottom: 8px;
}
.common-two-column_text p {
  color: rgba(93, 95, 108, 0.75);
}
.common-two-column_text ul {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 42px;
  justify-content: center;
  padding-bottom: 28px;
  padding-left: 20px;
}
.common-two-column_text li div {
  display: flex;
  align-items: center;
}
.common-two-column_text li div img {
  width: 38px;
  height: auto;
  justify-content: center;
  margin: 0 8px 0 0;
}
.common-two-column_text li p {
  margin-top: 12px;
}
.common-two-column_img {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 50%;
  height: 100%;
}
.common-two-column_img img {
  width: 100%;
  height: 100%;
}
.common-two-column_imgleft .common-two-column_img {
  left: 0;
}
.common-two-column_imgleft .common-two-column_text {
  right: 0;
}
.common-two-column_textleft .common-two-column_img {
  right: 0;
}
.common-two-column_textleft .common-two-column_text {
  left: 0;
}

/* 人 ----------------*/
.panel-wrap-person {
  background: #f0f0f5;
}
.person {
  background: #f0f0f5;
  padding-bottom: 120px;

  margin: auto;
  padding-top: 83px;
  /* width: ; */
}
.person-media {
  display: flex;
  align-items: center;
  margin-top: 80px;
}
.person-media p {
  width: 61%;
}
.person-media img {
  height: 40px;
  width: auto;
  margin-right: 25px;
}
.person-media h5 {
  margin-right: 12%;
}
.person-flow {
  position: relative;
  width: 100%;
  border-radius: 13px;
  margin-top: 40px;
  overflow: hidden;
}
.flow-top {
  position: relative;
  width: 100%;
  background: #fff;
  padding-bottom: 20%;
  z-index: 1;
}
.flow_circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 83%;
  padding-bottom: 11.7%;
}
.flow-top_circle {
  bottom: 0;
  border: 1px solid #6c38e0;
  border-width: 1px 1px 0 1px;
  border-radius: 1000px 1000px 0 0;
}
.flow-bottom_circle {
  top: 0;
  border: 1px solid #fff;
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 1000px 1000px;
}
.flow-bottom {
  position: relative;
  width: 100%;
  background: #6c38e0;
  padding-bottom: 24.6%;
}
.material {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.earpods {
  position: absolute;
  top: 10%;
  left: 1.8%;
  width: 13.8%;
  z-index: 2;
}
.earpods img {
  width: 100%;
}
.earpods .dot {
  position: absolute;
  right: -8%;
  top: -3%;
  width: 50%;
  animation: blink 1s 15 ease-in;
}
@keyframes blink {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.earpods2 {
  position: absolute;
  top: 3px;
  right: 0;
  width: 13.5%;
  z-index: 2;
}
.arrow {
  position: absolute;
}
.flow_circle ul {
  position: absolute;
  left: 0;
  top: -26px;
  width: 100%;
  height: 200%;
  display: flex;
  box-sizing: border-box;
  padding: 0 0 0 108px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  z-index: 10;
}
.flow_circle ul li {
  width: 34%;
}
.flow_circle ul li:nth-child(3n) {
  width: 32%;
}
.flow_circle ul li:nth-child(4),
.flow_circle ul li:nth-child(5) {
  position: relative;
  top: 58.4%;
}
.flow-title {
  font-size: 16px;
  color: #000108;
  margin-bottom: 9px;
}
.flow-title-light {
  color: #f0f2f5;
}
.flow-text {
  font-size: 12px;
  color: #98989e;
}
.flow-text-ligth {
  color: rgba(240, 242, 245, 0.5);
}
.comments {
  position: absolute;
  height: 24px;
  /* opacity: 0; */
}
.comments1 {
  left: 0;
  bottom: -120px;
}
.comments2 {
  right: -54px;
  bottom: -70px;
}
.comments3 {
  right: 40px;
  bottom: -120px;
}

/* 货 --------------------- */
.panel-wrap-product {
  background: #100029;
}
.product {
  position: relative;
  background: rgba(16, 0, 41, 1);
  margin: auto;
  padding-top: 89px;
  padding-bottom: 100px;
}
.panel-wrap-product_bg {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: url(../../assets/img/home/panel3-bg.png);
  background-repeat: no-repeat;
  background-size: 125%;
  background-position: center bottom;
  left: 50%;

  transform: translateX(-50%);
  bottom: 0;
}
.underline-fade-in {
  position: relative;
  display: inline-block;
}
.underline-fade-in_insert {
  position: absolute;
  bottom: -10px;
  width: 96%;
  height: 5px;
  background-image: url(../../assets/img/home/panel3-underline.png);
  background-size: 100% 100%;
  clip-path: inset(0 100% 0 0);
  left: 0;
}
.product-update {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 86px;
}
.product-update_status {
  position: relative;
  width: 49%;
  padding-bottom: 26.6%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 16px;
}
.product-update_on {
  background-image: url(../../assets/img/home/panel3-bg1.png);
}
.product-update_after {
  background-image: url(../../assets/img/home/panel3-bg2.png);
}
.product-update_status p {
  position: absolute;
  bottom: 21px;
  left: 20px;
  color: rgba(240, 242, 245, 0.3);
  font-size: 16px;
}
.product-survice {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding: 224px 0 289px; */
  padding-top: 100px;
  align-items: center;
}
.product-survice_left {
  width: 49%;
  position: relative;
}
.product-survice_right {
  position: relative;
  width: 51%;
}
.product-survice_right img {
  width: 95.3%;
}
.product-survice p {
  margin-top: 20px;
}
.product-shadow {
  position: absolute;
  bottom: -100px;
  right: 0;
  width: 55%;
  padding-bottom: 35%;
  background: linear-gradient(142deg, #503ad9 18%, #722ab8 147%);
  filter: blur(200px);
  opacity: 0.4;
  transform: rotate(-20deg);
}

/* 场 ---------------------- */
.panel-wrap-market {
  background: #f0f0f5;
  position: relative;
}
.market {
  background: #f0f0f5;
  margin: auto;
  padding-top: 89px;
  padding-bottom: 120px;
}
.livesale {
  position: relative;
  width: 100%;
  padding-bottom: 51%;
  background: url(../../assets/img/home/panel4-bg.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 80px;
}
.market .common-two-column_text li div img {
  width: 22px;
}
.livesale_desc {
  position: absolute;
  right: 1.9%;
  top: 37%;
  transform: translateY(-50%);
  width: 30.2%;
}
.livesale_desc .sec-text {
  margin-top: 8px;
}
.bullets {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3.7%;
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
}
.bullets li img {
  height: 42px;
}
.bullets li {
  /* position: relative;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  background: linear-gradient(103deg, rgba(255,255,255,0.40) 19%, rgba(255,255,255,0.00) 80%);
  padding: 8px 6px 8px;
  box-sizing: border-box;
  border: 1px dashed ;
  min-width: 18.6%;
  backdrop-filter: blur(10.88px);
  border-image: linear-gradient(276deg, #FFFFFF -35%, rgba(255,255,255,0.00) 87%) 1;
  clip-path: inset(0 round 8px); */
  /* border-radius: 8px; */
}
.bullets li::before {
  /* content: '';
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius:15px;
  background-color:#6CF; */
  /* background:linear-gradient(276deg, #FFFFFF -35%, rgba(255,255,255,0.00) 87%);
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  border: solid 1px transparent; */
  /* background-image: linear-gradient(#fff, #fff),
    linear-gradient(135deg, rgba(183, 40, 255, 1), rgba(40, 112, 255, 1)); */
}
.bullets .fans-tag {
  width: fit-content;
  color: #f0f2f5;
  padding: 4px 10px;
  background: rgba(240, 242, 245, 0.1);
  border-radius: 50px;
  margin-right: 12px;
}
.bullets span {
  color: rgba(255, 255, 255, 1);
  padding-right: 4px;
}
.function {
  position: absolute;
  width: 100%;
  height: 18%;
  bottom: 10%;
  display: flex;
  column-gap: 1.8%;
  justify-content: center;
}
.function li {
  width: 14.2%;
  background: #fff;
  border-radius: 8px;
  height: 100%;
  padding: 18px 16px 0;
  font-size: 16px;
  color: #000108;
  box-sizing: border-box;
  /* padding-bottom: 118px; */
}
.function li span {
  color: rgba(108, 56, 224, 1);
}
.voice-type {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 2.16%;
  margin-top: 13.6%;
}
.voice-type img {
  width: 10.8%;
}
.shop-bar {
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 33.8%;
  /* left: 50%;
  transform: translateX(-50%); */
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  padding-left: 1%;
}
.shop-bar .cart {
  width: 2.5%;
}
.shop-bar .shop-icon {
  width: 2.5%;
  padding-bottom: 2.5%;
  position: relative;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 50px;
}
.shop-bar .shop-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.shop-bar .shop-icon .menu {
  width: 44%;
}
.shop-bar .shop-icon .phone {
  width: 44%;
}
.shop-bar .shop-icon .share {
  width: 57.8%;
}
.shop-bar .shop-icon .heart {
  width: 50%;
}
.shop-bar .bubble {
  width: 50%;
  padding-bottom: 50%;
  position: absolute;
  background: url(../../assets/img/home/panel4-icon4.png) no-repeat;
  background-size: 100%;
  animation: bubble_y 4s linear infinite forwards;
}
@keyframes bubble_y {
  0% {
    bottom: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    bottom: 200px;
    opacity: 0;
    transform: scale(0.4);
  }
}
.shop-input {
  width: 10.5%;
  height: 100%;
  border-radius: 38px;
  background: rgba(255, 255, 255, 0.09);
  color: rgba(240, 242, 245, 0.3);
  padding: 4px 13px;
  line-height: 24px;
}

/* 安全 -------------------------------- */
.panel-wrap-security {
  position: relative;
  background: rgba(16, 0, 41, 1);
}
.security {
  position: relative;
  background: rgba(16, 0, 41, 1);
  padding-top: 80px;
  margin: auto;
  position: relative;
  padding-bottom: 50px;
}
.panel.security {
  position: relative;
}
.security .common-two-column_text li div img {
  width: 22px;
}
.security .common-two-column_text li:nth-child(2) div img {
  width: 18px;
}
.security .common-two-column_text li:nth-child(5) div img {
  width: 19px;
}
.panel-wrap-security_bg {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: url(../../assets/img/home/panel4-bg2.png);
  background-repeat: no-repeat;
  background-size: 125%;
  background-position: center bottom;
  left: 50%;

  transform: translateX(-50%);
  bottom: 0;
}

/* 能力矩阵 ----------------------- */
.cpacity-wrap {
  position: relative;
  width: 100%;
  background: #fff;
  z-index: 10;
}
.cpacity {
  width: 1280px;
  padding: 80px 0;
  margin: auto;
  background: #fff;
}
.cpacity ul {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.cpacity li {
  width: 23.6%;
  height: 100%;
  padding-bottom: 29.8%;
  border-radius: 16px;
  position: relative;
  transition: filter 0.2s;
  overflow: hidden;
}
.cpacity li:hover {
  /* filter: saturate(0.5); */
}
.cpacity li:hover .cpacity-cont {
  /* transform: translateY(-50%) scale(1.1); */
  filter: drop-shadow(2px 4px 6px black);
}
.cpacity li:hover .cpacity-icon_bg {
  transform: scale(15);
}
.cpacity-icon {
  position: absolute;
  top: 6.3%;
  left: 7.9%;
  width: 16.6%;
  padding-bottom: 16.6%;
}
.cpacity-icon_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: all 0.8s ease;
}
.cpacity-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38%;
}
.cpacity-icon:nth-child(2) img {
  width: 43%;
}
.cpacity-icon:nth-child(3) img {
  width: 48%;
}
.cpacity-icon:nth-child(4) img {
  width: 40%;
}
.cpacity-cont {
  position: absolute;
  top: 30%;
  /* transform: translateY(-50%); */
  left: 7.9%;
  width: 84.1%;
  transition: all 0.2s;
}
.cpacity-cont p {
  margin-top: 8px;
}
.cpacity-page {
  position: absolute;
  bottom: 6.3%;
  left: 7.9%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.header_wrap {
  position: fixed;
  transition: all 0.2s;
}
/* 1280px-990px ---------------------------------------------------------------------------------------------------------------- */

@media (max-width: 1280px) and (min-width: 990px) {
  /* @media ( max-width) */
  /* @media ( max-width: 1280px )  { */
  .panel,
  .cpacity {
    width: 90%;
    min-width: 990px;
    margin: auto;
  }

  .common-title h3 {
    font-size: 34px;
  }
  .common-title p {
    font-size: 20px;
  }

  .sec-title {
    font-size: 22px;
  }
  .sec-text {
    font-size: 12px;
  }

  .desc {
    padding-top: 7.5%;
  }

  .panel-main-wrap {
    width: 100%;
  }
  .panel-main {
    width: 90%;
  }
  .panel-main-right .circle {
    top: 34.2%;
  }
  .panel-main-right_login .panel-main-right_pos .circle {
    top: 39.2%;
  }
  .panel-main-right_pos {
    position: relative;
  }
  .panel-main-right {
    background-image: url(../../assets/img/home/panel1-person.png);
    background-size: 168% auto;
    /* margin-top: 80px; */
    background-position: 35% bottom;
  }
  .panel-main-right_animation {
    background-size: 192% auto;
    background-position: 40% bottom;
  }
  .panel-main-right:hover {
    background-size: 192% auto;
    background-position: 40% bottom;
  }
  @keyframes bgScale {
    100% {
      background-size: 168% auto;
      background-position: 35% bottom;
    }
  }
  .import-export {
    width: 90%;
  }
  .desc-title {
    font-size: 46px;
    margin: 13px 0 0;
  }
  .panel-wrap-main {
    background: linear-gradient(180deg, #100029 50%, #000 80%);
  }
  .big-module_title {
    font-size: 46px;
  }
  .big-module_text {
    font-size: 17px;
  }
  .person-media h5 {
    flex-shrink: 0;
    margin-right: 8%;
  }
  .person-media img {
    height: 30px;
    margin-right: 19px;
  }
  .flow-title {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .flow-text {
    font-size: 9px;
  }
  .flow_circle ul {
    top: -19px;
  }
  .comments {
    height: 18px;
  }
  .comments1 {
    left: 0;
    bottom: -90px;
  }
  .comments2 {
    right: -40x;
    bottom: -52px;
  }
  .comments3 {
    right: 30px;
    bottom: -90px;
  }

  .common-two-column_text {
    padding: 60px 30px 0;
  }

  .bullets {
    row-gap: 5px;
  }
  .bullets li img {
    height: 32px;
  }
  .shop-input {
    height: 24px;
    box-sizing: border-box;
    padding: 0 5px;
    font-size: 10px;
  }
  .function li {
    padding: 12px 10px 0;
    font-size: 12px;
  }
}
/* 990px以下---------------------------------------------------------------------------------------------------------------- */
@media (max-width: 989px) {
  .panel,
  .cpacity {
    width: 90%;
    /* min-width: 990px; */
    margin: auto;
  }
  .person,
  .product,
  .market,
  .security,
  .cpacity {
    padding-top: 40px;
  }

  .product,
  .person,
  .panel-main-wrap {
    padding-bottom: 50px;
  }

  .common-title h3 {
    font-size: 20px;
  }
  .common-title p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .common-title .icon {
    /* width: 24px; */
    /* width: 0; */
    height: 16px;
    vertical-align: bottom;
  }

  .sec-title {
    font-size: 13px;
  }
  .sec-text {
    font-size: 9px;
  }

  .desc {
    padding-top: 3%;
  }
  .desc-detail {
    margin-top: 16%;
  }
  .desc-d-text {
    font-size: 9px;
    flex: 1;
    padding-right: 20px;
  }
  .members {
    font-size: 9px;
  }

  .panel-main-wrap {
    width: 100%;
  }
  .panel-main {
    width: 90%;
  }
  .panel-main-right .circle {
    top: 34.2%;
  }
  .panel-main-right_login .panel-main-right_pos .circle {
    top: 39.2%;
  }
  .panel-main-right_pos {
    position: relative;
    display: none;
  }
  .panel-main-right {
    background-image: url(../../assets/img/home/panel1-person.png);
    background-size: 168% auto;
    /* margin-top: 80px; */
    background-position: 35% bottom;
  }
  .panel-main-right_animation {
    background-size: 192% auto;
    background-position: 40% bottom;
  }
  .panel-main-right:hover {
    background-size: 192% auto;
    background-position: 40% bottom;
  }
  @keyframes bgScale {
    100% {
      background-size: 168% auto;
      background-position: 35% bottom;
    }
  }
  .import-export {
    width: 90%;
  }
  .desc-title {
    font-size: 27px;
    margin: 13px 0 0;
  }
  .panel-wrap-main {
    background: linear-gradient(180deg, #100029 50%, #000 80%);
  }
  .big-module_title {
    font-size: 25px;
  }
  .big-module_text {
    font-size: 10px;
  }
  .import-export {
    font-size: 10px;
    height: 300px;
    margin-top: -30px;
  }
  .import-export img {
    width: 20%;
  }
  .panel-main-info {
    font-size: 10px;
  }
  .panel-main_bottombg {
    transform: scale(0.7) translate(-50%, 75%);
    transform-origin: center;
  }
  .panel-main_bottombg.hide {
    /* visibility: hidden; */
    opacity: 0;
    /* bottom: 80px; */
    transform: scale(0.7) translate(-50%, 10px);
  }
  .person-media h5 {
    flex-shrink: 0;
    margin-right: 8%;
  }
  .person-media img {
    height: 30px;
    margin-right: 19px;
  }

  .product-survice {
    padding-top: 40px;
  }
  .flow_circle ul {
    padding-left: 17%;
  }
  .flow-title {
    font-size: 9px;
    margin-bottom: 4px;
  }
  .flow-text {
    font-size: 6px;
  }
  .flow_circle ul {
    top: -13px;
  }
  .comments {
    height: 12px;
  }
  .comments1 {
    left: 0;
    bottom: -89%;
  }
  .comments2 {
    right: -40x;
    bottom: -60%;
  }
  .comments3 {
    right: 30px;
    bottom: -89%;
  }

  .common-two-column {
    margin-top: 25px;
  }
  .common-two-column_text {
    padding: 20px 15px 0;
  }
  .common-two-column_text ul {
    padding-left: 5px;
    font-size: 10px;
    padding-bottom: 0;
    row-gap: 4%;
    box-sizing: border-box;
  }
  .common-two-column_text li p {
    margin-top: 8px;
  }
  .common-two-column_text li div img {
    width: 22px;
  }
  .market .common-two-column_text li div img {
    width: 16px;
  }

  .bullets {
    row-gap: 3px;
  }
  .bullets li img {
    height: 18px;
  }
  .shop-input {
    height: 19px;
    line-height: 19px;
    box-sizing: border-box;
    padding: 0 3px;
    font-size: 6px;
  }
  .function li {
    padding: 7px 6px 0;
    font-size: 7px;
  }
  .cpacity-cont {
    top: 32%;
  }
  /* .cpacity-cont .sec-title {
    font-size: 11px;
  }
  .cpacity-cont p {
    font-size: 8px;
  } */
  .cpacity ul {
    row-gap: 10px;
    justify-content: space-around;
  }
  .cpacity li {
    width: 45%;
  }
}
</style>
