<!--
 * @Date: 2023-09-26 11:11:50
 * @LastEditTime: 2023-09-26 13:52:36
 * @Description: file content
-->
<template>
  <div v-show="goTopShow" class="back-top" :class="theme" @click="handleGoTop"></div>
</template>

<script>
  export default {
    props: {
      // 主题色 'dark' or 'light'
      theme: {
        type: String,
        default: 'dark'
      }
    },
    data() {
      return {
        goTopShow: false,
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleGoTop() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 450) {//设置页面滑动多少显示按钮
          this.goTopShow = true;
        } else {
          this.goTopShow = false;
        }
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }
</script>

<style>
  .back-top {
    position: fixed;
    z-index: 11;
    right: 23px;
    top: 48%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 23px;
    height: 24px;
    transition: all .4s;
    cursor: pointer;
  }
  .back-top.dark {
    background-image: url(../../../assets/img/home/top_dark.png);
  }
  
  .back-top.light {
    background-image: url(../../../assets/img/home/top_light.png);
  }
  .back-top:hover {
    background-image: url(../../../assets/img/home/top_hover.png);
  }
  .back-top:active {
    background-image: url(../../../assets/img/home/top_click.png);
  }

</style>